<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
      <template slot="title">
      </template>
      <gmap-map
        :zoom="16"
        :center="center"
        style="width:100%;  height: 600px;"
      >
        <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
        </gmap-info-window>
        <gmap-marker
          :key="index"
          v-for="(m, index) in locationMarkers"
          :position="m.position"
          :clickable="true"
          @click="toggleInfoWindow(m,i)"
        ></gmap-marker>
      </gmap-map>
      </a-card>
    </div>
  </div>
</template>

<script>
// import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'AddGoogleMap',
  // computed: {
  //   google: gmapApi,
  // },
  mounted () {
    this.locateGeoLocation()
  },
  data() {
    return {
      center: { lat: -8.003289, lng: 112.610641 },
      locationMarkers: [
        {
          position: {
            lat: -8.003289,
            lng: 112.610641,
          },
          infoText: '<strong>Something wrong</strong>',
        },
        {
          position: {
            lat: -8.002570,
            lng: 112.612753,
          },
          infoText: '<strong>Something wrong!</strong>',
        },
      ],
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
        content: '',
        // optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      locPlaces: [],
      existingPlace: null,
    }
  },
  methods: {
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position
      this.infoOptions.content = marker.infoText

      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else { // if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    initMarker(loc) {
      this.existingPlace = loc
    },
    addLocationMarker() {
      if (this.existingPlace) {
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng(),
        }
        this.locationMarkers.push({ position: marker })
        this.locPlaces.push(this.existingPlace)
        this.center = marker
        this.existingPlace = null
      }
    },
    locateGeoLocation: function() {
      navigator.geolocation.getCurrentPosition(res => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        }
      })
    },
  },
}
</script>

<style>

</style>
